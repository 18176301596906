import React from 'react';
import SEO from '../components/seo';
import Container from '../components/Container'
import TitlePage from '../components/TitlePage';
import RichText from '../components/RichText'

const NotFound = () => (
  <Container>
    <SEO title="404: Not found" />
    <TitlePage text="404 - de opgevraagde pagina kon niet gevonden worden"/>
    <RichText>
      <p>404 - the requested page could not be found</p>
    </RichText>
    
  </Container>
);

export default NotFound;
